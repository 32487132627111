import { Utils, AssetGroup, FormLibrary } from 'Shared/utils';
import { setModalIsOpen } from 'Shared/ifixit_store';

onDomReady(function () {
   (function () {
      Modal.initialize();
   }.delay(100));
});

/**
 * Class Modal
 *
 * A static class for displaying content in a modal "window" that sits over the
 * center of the page. There is only one modal window at any given time, and
 * trying to open another replaces the current content with the new content.
 * The two ways to display content in a modal are by calling Modal.open or by
 * clicking on any element which has the class 'modal' (Modal automatically
 * initializes these elements on domready). You can also call
 * Modal.alert('text'), which shows a basic alert message.
 *
 * Examples:
 *    (begin code)
 *    // Open a modal of type 'module', which loads in content via AJAX;
 *    // 'message' is an option specific to the Login module.
 *    Modal.open({
 *       type: 'module',
 *       name: 'Login',
 *       message: 'Log in here.'
 *    });
 *
 *    <!-- duplicate the element with id "shortcuts" and show it in a modal -->
 *    <a class="modal" href="#shortcuts">Keyboard shortcuts</a>
 *    (end)
 *
 * Use CSS to set widths and heights for modal content, and Modal will do its
 * best to get the right size within the constraints of the browser viewport.
 */
// eslint-disable-next-line no-var
export var Modal = (window.Modal = {
   /**
    * Placed here only for easy accessibility; these are options which should
    * NOT be overwritten by the application, and which control the basic
    * behavior of the modal.
    */
   config: {
      width: 250, // base width, before the content is sized
      height: 250, // base height
   },

   /**
    * A simple state table to help us decide when to ignore requests and avoid
    * the opening animation. Each field is a simple constant.
    */
   state: {
      closed: 0,
      opening: 1,
      open: 2,
   },

   locked: false,

   isLoading: false,

   animationDuration: 250,

   stack: [],

   closeConfirms: new Hash(),

   /**
    * Must be called on domready; inserts the necessary HTML, sets defaults for
    * class variables, and sets a 'click' event handler for page elements with
    * class 'modal'.
    */
   initialize: function () {
      if (this.initialized) {
         return;
      }

      // So we can just reference a consistent this.cancel
      this.cancel = this.cancel.bind(this);

      this.initialized = true;
      this.current = null;
      this.currentState = this.state.closed;
      this.build();

      // Set up the overlay, and bind its click event to close the modal.
      this.overlay = new Overlay(window, {
         onClick: this.cancel,
      });

      // Bind the escape handler once.
      this.handleEscape = this.handleEscape.bind(this);

      // Initialize Modal elements.
      $$('.modal').eachAddEvent('click', this.handleClick, this);
   },

   isOpen: function () {
      return this.currentState != this.state.closed;
   },

   open: function (options) {
      this.initialize();

      // Ignore open requests while we're opening.
      if (this.currentState == this.state.opening) {
         return;
      }

      // Are we starting from a closed or open state?
      if (this.currentState == this.state.closed) {
         // Closed, so we need to add the escape handler,
         // show the overlay, and open the modal box.
         this.addEscapeHandler();
         if (!options.noOverlay) {
            this.overlay.show();
         }
      } else {
         // Open, so we need to save any current content;
         // the overlay and modal box are already visible.
         let content = this.contentBox.getFirst();
         if (content) {
            content.dispose();
         }

         this.current.content = content;
         this.stack.append([this.current]);
      }

      // Whatever our state was, we're opening now.
      this.currentState = this.state.opening;
      this.closeBtn.hide();

      // Set up the information for the current content.
      this.current = {
         options: options,
      };

      if (options.locked) {
         this.locked = true;
      }

      if (options && !options.keepHidden) {
         this.box.show();
      }

      setModalIsOpen(true);

      // Load in the content.
      this.loadContent();
   },

   handleClick: function (el, ev) {
      ev.stop();
      let options = {},
         href;
      Array.convert(el.attributes).each(function (a) {
         if (/^data-modal-/.test(a.name)) {
            options[a.name.replace(/^data-modal-/, '')] = a.value;
         }
      });
      if (!options.type) {
         options.type = 'element';
      }
      if (!options.href && (href = el.get('href'))) {
         options.href = href.replace(/.*(#.*$)$/, '$1');
      }
      this.open(options);
   },

   pop: function (cancel) {
      if (!this.stack.length) {
         return this.close(cancel);
      }

      if (!this.closeConfirm()) {
         return;
      }

      this._onClose(cancel);

      this.unloadContent();

      this.current = this.stack.getLast();
      this.stack.erase(this.current);

      this.current.content.inject(this.contentBox);
   },

   /**
    * Closes the modal and calls options.onCancel() if set.
    *
    * Note: Called when the user clicks the X or clicks outside the dialog
    */
   cancel: function () {
      this.pop(true);
   },

   close: function (cancelling) {
      let modal = this;
      let isModal = modal.box && modal.overlay;

      if (modal.currentState != modal.state.open || modal.locked) {
         return false;
      }

      if (!modal.closeConfirm()) {
         return false;
      }

      // Add closing animation to modal.
      if (isModal && modal.animationDuration) {
         modal.box.addClass('modalClosing');
         modal.overlay.overlay.addClass('overlayClosing');
      }

      setModalIsOpen(false);

      // Wait for closing animation to complete before disposal.
      function close() {
         if (isModal && modal.animationDuration) {
            modal.box.removeClass('modalClosing');
            modal.overlay.overlay.removeClass('overlayClosing');
         }
         modal.currentState = modal.state.closed;
         let options = modal.current.options;

         // if the modal is already cleared, bail
         if (!options) {
            return false;
         }

         modal._onClose(cancelling);

         // Unload the content
         modal.unloadContent();

         // Empty the stack
         modal.stack.each(function (module) {
            modal.current = module;
            modal.unloadContent();
         }, modal);

         modal.stack = [];

         // And close everything
         modal.removeEscapeHandler();
         if (modal.isLoading) {
            modal.doneLoading();
         }
         modal.hide();
         modal.overlay.hide();
      }

      if (modal.animationDuration) {
         close.delay(modal.animationDuration);
      } else {
         close();
      }

      return true;
   },

   _onClose: function (cancel) {
      let options = this.current.options;
      if (options.onClose) {
         options.onClose();
      }
      // Only fire this once
      delete options.onClose;

      if (options.onCancel && cancel) {
         options.onCancel();
      }
      // Only fire this once
      delete options.onCancel;
   },

   /**
    * Closes the modal even if it is locked
    */
   forceClose: function () {
      this.locked = false;
      this.close(true);
   },

   closeConfirm: function () {
      return this.closeConfirms.every(function (fn) {
         return fn.apply();
      });
   },

   handleEscape: function (ev) {
      if (ev.key == 'esc') {
         ev.stop();
         this.cancel();
      }
   },

   /* Convenience method for showing a basic alert. */
   alert: function (text) {
      this.open({
         type: 'message',
         message: text,
      });
   },

   /**
    * Dims the modal contents and displays a loading/processing message.
    *
    * The optional second argument will be displayed beneath the first in a
    * smaller font.
    */
   loading: function (msg1, msg2) {
      this.throbber.show();
      this.isLoading = true;
   },

   doneLoading: function () {
      this.isLoading = false;
      this.throbber.hide();
      return this;
   },

   openModalImg: function (imgUrl, width, height) {
      let m = new Element('div', {
         styles: {
            overflow: 'hidden',
            margin: '0 20px',
            maxWidth: 800,
            maxHeight: 600,
            width: width || 'auto',
            height: height || 'auto',
         },
      });

      let i = new Element('img', {
         src: imgUrl,
         styles: {
            maxWidth: '100%',
            maxHeight: 600 - 16,
            verticalAlign: 'middle',
         },
      });
      m.adopt(i);

      Modal.open({
         type: 'element',
         element: m,
      });
   },

   // PRIVATE METHODS /////////////////////////////////////////////////////////

   build: function () {
      let cancel = this.cancel;
      this.box = new Element('div.modalBox');
      this.contentBox = new Element('div.modalContentBox');
      this.closeBtn = new Element('i.fa.fa-times.modalCloseBtn').addEvent('click', cancel);

      this.box.inject(document.body).adopt(this.contentBox).hide();

      this.throbber = new Element('div.throbber').inject(this.box).hide();
      let _this = this;
      let outsideOfModal = false;

      /**
       * Cancel the modal if a click happens outside of it.
       *
       * It is tempting to use a 'click' event handler here, but that has a
       * serious flaw. A mousedown inside the modal followed by a mouseup
       * outside it causes a "click" outside. This has implications for the
       * markers dialog and draggin / dropping; namely if you drag the mouse
       * outside and release, it would trigger the 'click' event.
       */
      this.box.addEvents({
         mousedown: function (event) {
            // Only cancel if the clicked element is either of the boxes used for
            // modal positioning.
            // Note: we can't do !content.contains(event.target) cause the content
            // has possibly been swapped by this point (i.e. because of .pop())
            outsideOfModal = event.target == _this.contentBox || event.target == _this.box;
         },
         mouseup: function (event) {
            if (outsideOfModal) {
               cancel();
               outsideOfModal = false;
            }
         },
      });
   },

   hide: function () {
      this.box.removeClass('modalOpen');
      return this.box.hide();
   },

   show: function () {
      this.box.addClass('modalOpen');
      return this.box.show();
   },

   // This is for accessibility purposes. This sets the element's tabIndex to
   // 0, which will allow keyboard navigation within the element.
   focus: function (el) {
      el.setAttribute('tabIndex', 0);
      el.focus();
   },

   loadContent: function () {
      this.throbber.show();
      // Dispatch to the handler for the current type.
      let handler = this.current.options.type;
      this.handlers[handler].load.call(this);
   },

   contentLoaded: function (content) {
      let options = this.current.options;

      if (options.defaultWidth) {
         content.addClass('defaultWidth');
      }
      content.adopt(this.closeBtn);

      this.throbber.hide();
      this.current.content = content;
      content.setStyle('display', '');
      content.addClass('modal-content');
      this.contentBox.adopt(content);
      this.focus(content);

      let handler = this.handlers[options.type];
      if (handler.loaded != undefined) {
         // Using delay() here so it doesn't happen on the exact same turn of
         // the event loop as handler.load; some modals were rather messed up
         // (image_crop).
         handler.loaded.delay(0, this);
      }

      let onLoad = options.onLoad;
      onLoad && onLoad.call(this, content);

      if (!this.locked) {
         this.closeBtn.show();
      }

      this.currentState = this.state.open;
      this.show();
   },

   unloadContent: function () {
      if (this.current) {
         let handler = this.handlers[this.current.options.type];
         if (handler.unload != undefined) {
            handler.unload.call(this);
         }
      }

      let content = this.contentBox.getFirst();
      if (content) {
         content.dispose();
      }

      this.current = {};
   },

   lock: function () {
      this.closeBtn.hide();
      this.locked = true;
      return this;
   },

   unlock: function () {
      this.closeBtn.show();
      this.locked = false;
      return this;
   },

   addEscapeHandler: function () {
      window.addEvent('keydown', this.handleEscape);
   },

   removeEscapeHandler: function () {
      window.removeEvent('keydown', this.handleEscape);
   },

   // HANDLERS ////////////////////////////////////////////////////////////////

   handlers: {
      element: {
         load: function () {
            let el;
            let options = this.current.options;
            if (options.element) {
               el = options.element;
            } else {
               let id = Array.pick([options.selector, options.href]);
               el = $(id.replace(/[^a-zA-z_-]/, ''));
            }
            el.inject(document.body).hide();
            this.contentLoaded(el);
         },

         loaded: function () {
            this.fireEvent('onElementDisplay', [this, this.current.content]);
         },

         unload: function () {
            this.current.content.hide().inject(document.body);
            this.fireEvent('onElementUnload', [this, this.current.content]);
         },
      },

      message: {
         load: function () {
            let options = this.current.options;

            if (!options.message) {
               throw "You must provide a 'message' option, which contains a " + 'string message.';
            }

            // The content container.
            let content = new Element('div').addClass('modalMessage');

            // The message.
            new Element('p', {
               html: options.message,
            })
               .addClass('modalMessageText')
               .inject(content);

            let buttonsDiv = new Element('p');
            buttonsDiv.addClass('modalMessageButtons');

            let buttons = options.buttons;

            // If no buttons are specified, add a default button that simply
            // closes the modal.
            if (!buttons) {
               buttons = {};
               buttons[_js('Okay')] = function () {};
            }

            // Clicking a button closes the modal and runs the button clicked
            // handler.
            let buttonClicked = function (callback) {
               this.close();
               callback.apply();
            };

            let buttonsCount = Hash.getLength(buttons);

            if (buttonsCount > 2) {
               buttonsDiv.addClass('full-width');
            }

            Hash.each(
               buttons,
               function (callback, label) {
                  let button = new Element('button', {
                     id: ('button' + label).replace(/[^a-zA-Z]/g, ''),
                     text: label,
                     class:
                        'button ' +
                        (label === 'Okay' ? ' okay-button' : '') +
                        (label === _js('Cancel')
                           ? 'button-link'
                           : 'button-action button-action-solid'),
                     events: { click: buttonClicked.bind(this, callback) },
                  });

                  button.inject(buttonsDiv);
               },
               this
            );

            buttonsDiv.inject(content);

            this.contentLoaded(content);
         },

         loaded: function () {
            $E('.modalMessageButtons').getLast('button').focus();
         },
      },

      form: {
         load: function () {
            let options = this.current.options;
            let form = options.form;
            let onSubmit = options.onSubmit || function () {};
            let type = typeOf(form);
            if (type == 'element') {
               form.removeEvents('submit');
            } else if (type == 'object') {
               form = FormLibrary.create(options.form);
            }
            form.addEvent('submit', function (ev) {
               ev.stop();
               let values = FormLibrary.getValues(form);
               Modal.close();
               onSubmit(values);
            });
            this.current.form = form;
            this.contentLoaded(new Element('div').adopt(form));
         },

         loaded: function () {
            let onLoaded = this.current.options.onLoaded;
            FormLibrary.focusFirst(this.current.form);
            if (onLoaded) {
               onLoaded();
            }
         },

         unload: function () {},
      },

      module: {
         load: function () {
            let options = this.current.options;
            /**
             * Only added so the old image cropper and image markers dialogs
             * can coexist with different JS front-end names but use the
             * same backend endpoints.
             */
            let serverSideModule = options.serverModuleName || options.name;
            this.current.module = options.name;
            this.current.serverOptions = options.serverOptions || {};
            this.current.clientOptions = options.clientOptions || {};

            let content = new Element('div.wrapper');
            if (options.boxClass) {
               content.addClass(options.boxClass);
            }

            new Request.AjaxIO('loadModalAjaxModule', {
               onSuccess: function (response) {
                  if (this.currentState == this.state.opening) {
                     content.set('html', response.html);
                     content.hide().inject(document.body);

                     let timer = 0;
                     let assetsLoaded = function () {
                        if (!timer) {
                           return;
                        }

                        clearInterval(timer);

                        this.fireEvent('on' + this.current.module + 'Load', [
                           content,
                           this.current.clientOptions,
                           response.data,
                        ]);
                        this.contentLoaded(content);
                     };

                     // Complete after 5 seconds if the AssetGroup doesn't fire its event.
                     timer = assetsLoaded.delay(5000, this);

                     new AssetGroup(response.css, response.js, {
                        onComplete: assetsLoaded.bind(this),
                     });
                  }
               }.bind(this),
            }).send(serverSideModule, this.current.serverOptions);
         },

         loaded: function () {
            this.fireEvent('on' + this.current.module + 'Display', [
               this.current.clientOptions,
               this.current.serverOptions,
            ]);
         },

         unload: function () {
            this.fireEvent('on' + this.current.module + 'Unload', [
               this.current.clientOptions,
               this.current.serverOptions,
            ]);
         },
      },
   },
});
Object.append(Modal, new Options());
Object.append(Modal, Utils.EventsFunctions);

/**
 * Class Overlay
 *
 * A class that covers the page with a semi-transparent screen in order to draw
 * attention to a modal and provide an area for the user to click in order to
 * clear the modal.
 *
 * Example:
 *    (begin code)
 *    var overlay = new Overlay({
 *       onClick: this.close.bind(this)
 *    });
 *    (end)
 */
// eslint-disable-next-line no-var
export var Overlay = (window.Overlay = new Class({
   Implements: [Options, Events],

   options: {
      scrollToTop: false,
      scrollBuffer: 200,
      onClick: function () {},
      onHide: function () {},
      onShow: function () {},
   },

   /**
    * Constructor: Overlay
    *    Creates a new Overlay.
    */
   initialize: function (el, options) {
      this.element = el;
      this.setOptions(options);

      this.overlay = new Element('div.modal-overlay');

      // Fire an onClick event when the overlay is clicked.
      this.overlay.addEvent('click', this.fireEvent.bind(this, 'click'));
   },

   /**
    * Method: show
    *    Displays the overlay.
    */
   show: function () {
      this.fireEvent('onShow');
      this.overlay.inject(document.body);
      document.body.addClass('modal-displayed');

      this.originalScrollPosition = window.getScroll();

      if (this.options.scrollToTop) {
         window.scrollTo(0, 0);
      }

      return this;
   },

   hide: function () {
      this.fireEvent('onHide');
      this.overlay.unpin().dispose();
      document.body.removeClass('modal-displayed');

      if (this.options.scrollToTop) {
         window.scrollTo(0, this.originalScrollPosition.y);
      }

      return this;
   },
}));

/**
 * Class LoadingStatus
 */
// eslint-disable-next-line no-var
export var LoadingStatus = (window.LoadingStatus = new Class({
   Implements: Options,

   options: {
      contentTop: null,
      zIndex: 10001,
   },

   initialize: function (el, options) {
      this.element = el;
      this.setOptions(options);
      this.overlay = new Overlay(el, {
         styles: {
            backgroundColor: '#fff',
            zIndex: this.options.zIndex,
            // should be the same as the default opactiy from Overlay
            opacity: this.options.opacity || 0.7,
         },
         scrollToTop: false,
      });
      this.contentDiv = new Element('div.loading', {
         styles: {
            zIndex: this.options.zIndex + 1,
         },
      });
      this.message = new Element('p.message');
      this.subMessage = new Element('p.subMessage');
   },

   loading: function (msg1, msg2) {
      this.overlay.show();
      this.display(msg1, msg2);
   },

   update: function (msg1, msg2) {
      this.message.dispose();
      this.subMessage.dispose();
      this.display(msg1, msg2);
   },

   doneLoading: function () {
      this.contentDiv.dispose();
      this.message.dispose();
      this.subMessage.dispose();
      this.overlay.hide();
   },

   display: function (msg1, msg2) {
      let elCoords = this.element.getCoordinates();
      let contentHeight, top;

      if (msg1) {
         this.message.set('html', msg1).inject(this.contentDiv, 'top');
      }
      if (msg2) {
         this.subMessage.set('html', msg2).inject(this.message, 'after');
      }

      this.contentDiv
         .setStyles({
            top: 0,
            left: 0,
            width: elCoords.width,
            opacity: 0,
         })
         .inject(document.body);

      contentHeight = this.contentDiv.getHeight();
      if (this.options.contentTop !== null) {
         top = elCoords.top + this.options.contentTop;
      } else {
         top = elCoords.top + (elCoords.height - contentHeight) / 2;
      }

      this.contentDiv.unpin().setStyles({
         top: Math.floor(top),
         left: elCoords.left,
      });

      this.contentDiv.setStyle('opacity', 1);
   },
}));
