import { CSRF } from 'Shared/csrf';
import { Utils } from 'Shared/utils';
import { Auth } from 'Shared/auth';
import { MediaItem } from './item';
import { Modal } from 'Shared/modal';
import '../../../3P/FileDrop/filedrop';

// NOTE: that there are includes for the other basic MediaLibrary elements at
// the bottom of the file, after the base MediaLibrary is defined.

/**
 * See docs in the README for this directory
 *
 * Class MediaLibrary
 */
const MediaLibrary = {
   loadOptions: {
      accepts: 'all',
   },

   // Ensure this is exactly the same as the list in upload.php
   imageExtensions: {
      // Images
      jpeg: 'i',
      jpg: 'i',
      gif: 'i',
      png: 'i',
      bmp: 'i',
      tiff: 'i',
      tif: 'i',
   },

   documentExtensions: {
      pdf: 'd',
   },

   videoExtensions: {
      // Videos
      mov: 'v',
      avi: 'v',
      mp4: 'v',
      mpeg: 'v',
      wmv: 'v',
      ogg: 'v',
      flv: 'v',
   },

   _loaded: false,

   /* Events broadcaster that is thrown away when the dialog is unloaded */
   events: null,

   container: null,

   // Default filter options, set in loadUserMedia based on the media item type
   itemFilter: 'all',

   initialize: function () {
      // These events will be fired when some JS requests that the MediaLibrary
      // modal module be loaded or unloaded (when the modal is closed).
      this.allExtensions = Object.merge(
         Object.clone(this.imageExtensions),
         this.documentExtensions,
         this.videoExtensions
      );

      Modal.addEvents({
         onMediaLibraryLoad: function (contentBox, clientOptions, libraryData) {
            this.mediaItems = [];
            this.thumbnails = [];
            this._loaded = true;
            this.events = new Events();
            this.events.addEvents(clientOptions.events);

            this.container = contentBox.getElement('#mediaLibrary');
            this._deleteAll = $('deleteAllMedia');
            this.loadOptions = Object.append(this.loadOptions, clientOptions);
            this.mediaTarget = clientOptions.target;
            this.mediaFilterName = this.mediaTarget ? this.mediaTarget.getMediaFilterName() : null;

            clickSafe($('libraryCloseBtn'), function () {
               Modal.cancel();
            });

            this._initNavigation();

            // Initialize the upload pane.
            this.uploadUrl = $('mediaUploadFallback').action;

            /**
             * We don't care about the qqUploader UI, it ends up in here and is
             * purposely hidden.
             */
            this.mediaUploadArea = $('mediaUpload');

            this.createUploader();

            // Initialize the browse pane.

            this.browseWindow = $('mediaBrowse');

            this.alertsContainer = this.container.getElement('.statuses');

            this._setupDeleteAll();
            this.setupSorting();

            this._loadItems(libraryData.mediaItems);
         }.bind(this),

         onMediaLibraryUnload: function () {
            this._loaded = false;
            delete this.events;
         }.bind(this),
      });
   },

   _loadItems: function (libraryData) {
      let library = this;

      let attachedItems = library.loadOptions.attachedItems;

      let itemsData = libraryData.items;

      library.itemFilter = libraryData.itemFilter;

      library._initFilters();

      // _initFilters must be run before we fire this event
      library.events.fireEvent('itemCountChanged');

      // Turn each vanilla object into a full-fledged MediaItem
      // and add it to the browse pane.
      itemsData.reverse().each(function (itemData) {
         let mediaItem = MediaItem.createFromData(itemData);
         let hide = attachedItems[mediaItem.getGlobalID()];
         library.addItem(mediaItem, hide);
      });

      $('addMedia').addEvents({
         'click:relay(.js-url-embed-submit)': function (ev, el) {
            let imageExtensions = Object.keys(MediaLibrary.imageExtensions);
            let re = new RegExp(imageExtensions.join('|'));

            let url = $E('.js-url-embed-link').value;
            let isImageUrl = url.match(re);

            let body = {
               url: url,
            };

            let request = isImageUrl ? 'user/media/images/url' : 'user/media/embed';

            new Request.API_2_0(request, {
               method: 'post',
               onSuccess: function (response) {
                  let dataPromise = new Future();
                  let mediaItem = MediaItem.createFromData(response, {}, dataPromise);
                  let id = isImageUrl ? response.guid : response.objectid;

                  new Request.AjaxIO('isMediaItemReady', {
                     onComplete: function (responseData) {
                        if (responseData.error) {
                           dataPromise.error(responseData.error);
                        } else {
                           dataPromise.resolve(responseData);
                        }
                     },
                  }).ping(id, response.type, library.mediaFilterName);

                  library.addItem(mediaItem, false);
               },

               onFailure: function (xhr, message) {
                  MediaLibrary.appendAlertMessage(message, 'error');
               },
            }).send(body);
            library._toggleView();
         },
      });

      $('mediaLibrary').addEvents({
         'click:relay(.js-toggle-selected)': function (ev, el) {
            let isSelected = el.hasClass('selected');
            $$('.js-toggle-target').removeClass('toggle-open');
            $$('.js-toggle-selected').removeClass('selected');

            let target = el.getParent().getElement('.js-toggle-target');
            if (isSelected) {
               target.removeClass('toggle-open');
               el.removeClass('selected');
            } else {
               target.addClass('toggle-open');
               el.addClass('selected');
            }
         },

         'click:relay(.js-toggle-target)': function (ev, el) {
            $$('.js-toggle-target').removeClass('toggle-open');
            $$('.js-toggle-selected').removeClass('selected');

            let sortText = $E('.sortOption.active').get('text');
            $E('.js-current-sort-name').set('text', sortText);
         },
      });
   },

   /**
    * Show the media library for the given media target.
    *
    * @param mediaTarget MediaTarget instance
    * @param completeCallback(mediaItem) function to call when the library will
    *        be closed (mediaItem) may be null if process is cancelled.
    * @param attachedItems a key => value map of media items that should be
    *        hidden. This is used to hide placed, but unsaved items.
    */
   showForTarget: function (mediaTarget, completeCallback, attachedItems) {
      let accepts = mediaTarget.getMediaFilterName() || this.loadOptions.accepts;

      let doOpen = Modal.open.pass(
         [
            {
               type: 'module',
               name: 'MediaLibrary',
               boxClass: 'mediaLibraryModalBox',
               clientOptions: {
                  target: mediaTarget,
                  attachedItems: attachedItems,
                  events: {
                     itemSelected: completeCallback,
                  },
               },
               serverOptions: { accepts: accepts },
               onCancel: completeCallback,
            },
         ],
         Modal
      );

      if (!Auth.isLoggedIn()) {
         Auth.required({
            onAuthorize: doOpen,
            onCancel: completeCallback,
         });
      } else {
         doOpen();
      }
   },

   dismissAlerts: function () {
      let library = this;

      library.alertsContainer.getChildren().each(function (el) {
         let closeBtn = el.getChildren('.close-status').pick();
         if (typeof closeBtn !== 'undefined' && closeBtn !== null) {
            closeBtn.fireEvent('click');
         }
      });
   },

   _appendAlert: function appendAlert(element) {
      let alerts = this.alertsContainer.getChildren();
      let newAlert = true;
      if (alerts.length) {
         alerts.each(function (alert) {
            let countEl = alert.getElement('span.status-count');
            let newCount = false;

            if (!countEl) {
               countEl = new Element('span.status-count', { text: ' x 1' });
               countEl.set('data-count', 1);
               newCount = true;
            }

            let count = parseInt(countEl.get('data-count'), 10);

            if (element.get('data-text') === alert.get('data-text')) {
               newCount = count + 1;
               countEl.set('text', 'x ' + newCount);
               countEl.set('data-count', newCount);

               if (newCount) {
                  alert.grab(countEl);
               }

               newAlert = false;
            }
         });
      }

      if (newAlert) {
         this.alertsContainer.grab(element, 'top');
      }

      $('libraryContent').scrollTo(0, 0);
   },

   appendAlertMessage: function (message, type) {
      let msg = Utils.createAlert(type || 'error', message);
      this._appendAlert(msg);
   },

   appendPromptMessage: function (message, type, callback) {
      let msg = Utils.createAlertPrompt(type || 'notice', message, callback);
      this._appendAlert(msg);
   },

   createUploader: function () {
      let library = this;
      let uploadingItems = {};
      let cancelFunctions = {};
      let uploader = new FileDropUploader({
         uploadUrl: this.uploadUrl,
         container: this.mediaUploadArea,
      });

      let mediaItemUploader = new MediaItemUploader(uploader, {
         mediaFilterName: this.mediaFilterName,
      })
         .addEvent('added', function (id, filename, cancelFunction) {
            cancelFunctions[id] = cancelFunction;

            library._toggleView(true);
            library._selectFilter('all');

            getUploadingItem(id).setFilename(filename);
         })
         .addEvent('error', function (id, filename, response, mediaItem) {
            if (id !== null) {
               getUploadingItem(id).dispose();
               delete uploadingItems[id];
            }
            if (mediaItem) {
               mediaItem.fireEvent('deleted');
            }

            if (response) {
               let msg = Utils.createAlert('error', response);
               library._appendAlert(msg);
               library._toggleView(true);
            }
         })
         .addEvent('progress', function (id, filename, percent) {
            getUploadingItem(id).setFilename(filename).setProgress(percent);
         })
         .addEvent(
            'upload',
            function (id, mediaItem) {
               this.addItem(mediaItem, /* hide = */ false, getUploadingItem(id).element);
               delete uploadingItems[id];
            }.bind(this)
         );

      function getUploadingItem(id) {
         if (!uploadingItems[id]) {
            uploadingItems[id] = new UploadingItem(cancelFunctions[id]);
            uploadingItems[id].element.inject(library.browseWindow, 'top');
            library.events.fireEvent('itemCountChanged');
         }
         return uploadingItems[id];
      }
   },

   /**
    * Add a media item to the user interface.
    */
   addItem: function (mediaItem, hide, position) {
      let library = this;

      mediaItem.setContext(library);

      let thumbnail = mediaItem.createDisplay('standard', true);
      let element = thumbnail.getElement();

      if (hide) {
         element.hide();
      }

      if (typeOf(position) == 'element') {
         element.replaces(position);
      } else {
         element.inject(library.browseWindow, position || 'top');
      }

      thumbnail.addEvent('select', function (selected) {
         if (!library._loaded) {
            return;
         }

         library.events.fireEvent('itemSelected', selected);
      });

      mediaItem.addEvent('deleted', function () {
         if (!library._loaded) {
            return;
         }
         library.removeItem(this);
         library.thumbnails.erase(thumbnail);
      });

      library.mediaItems.push(mediaItem);
      library.thumbnails.push(thumbnail);
      library.events.fireEvent('itemCountChanged');

      // IE 11 bugfix to allow click events on a just-added thumbnail
      $('libraryContent').focus();

      return mediaItem;
   },

   removeItem: function (mediaItem) {
      this.events.fireEvent('itemCountChanged');

      // Remove the MediaItem from the DB.
      this.mediaItems.erase(mediaItem);
      new Request.AjaxIO('removeFromLibrary', {}).send(mediaItem.getID(), mediaItem.getType());
   },

   /**
    * Setup events and comparators for the Sorted By options.
    */
   setupSorting: function () {
      let comparators = {
         filename: function (thumbnailDisplayA, thumbnailDisplayB) {
            let valueA = thumbnailDisplayA.mediaItem.data.filename();
            let valueB = thumbnailDisplayB.mediaItem.data.filename();
            return valueA.localeCompare(valueB);
         },
         date: function (thumbnailDisplayA, thumbnailDisplayB) {
            let valueA = thumbnailDisplayA.mediaItem.data.date();
            let valueB = thumbnailDisplayB.mediaItem.data.date();
            return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
         },
      };

      let library = this;
      let sortOptions = $$('.sortOption');

      sortOptions.addEvent('click', function (event) {
         let comparatorName = event.target.get('data-comparator');
         library.setSortComparator(comparators[comparatorName]);
         sortOptions.removeClass('active');
         event.target.addClass('active');
      });
   },

   /**
    * Sets the comparator function used for sorting the MediaItemsDisplay
    * objects.
    */
   setSortComparator: function (comparator) {
      this.thumbnails.sort(comparator);

      let library = this;
      this.thumbnails.each(function (thumb) {
         library.browseWindow.grab(thumb.getElement(), 'bottom');
      });
   },

   /**
    * Return an array of name => boolean mappings that enable or disable
    * various mediaItem menu options.
    *
    * Disable copying in the media library, it's not something we want to
    * encourage.
    */
   getMenuItems: function (mediaItem) {
      return {
         copy: false,
      };
   },

   getItemCount: function () {
      return $$('#mediaBrowse > .mediaItem').filter(function (item, index) {
         return item.getStyle('display') != 'none';
      }).length;
   },

   /**
    * Shows either the upload pane or the browse pane.
    *  - Calling this without an argument will toggle the current mode
    *  - Passing a truthy argument will show the browse pane
    *  - Passing a falsy argument will show the upload pane
    */
   _toggleView: (function () {
      let viewingBrowse = true;
      return function (viewBrowse) {
         viewingBrowse = arguments.length ? viewBrowse : !viewingBrowse;
         $('mediaLibrary').toggle(viewingBrowse);
         $('addMedia').toggle(!viewingBrowse);

         let parent = $('addMedia').parentElement;
         if (viewingBrowse) {
            parent.setStyle('max-width', '');
         } else {
            parent.setStyle('max-width', '663px');
         }
      };
   })(),

   _initNavigation: function () {
      // Initialize library navigation
      $$('.mediaManagerNav').addEvent(
         'click',
         function (ev) {
            this._toggleView();
         }.bind(this)
      );

      $$('#emptyBrowseNotice button').addEvent(
         'click',
         function (ev) {
            this._toggleView();
         }.bind(this)
      );
   },

   /**
    * Setup the mediaitem filters (all / images / video)
    */
   _initFilters: function () {
      let self = this,
         filterButtons = $$('.itemFilter');

      filterButtons.addEvent('click', function (e) {
         self._selectFilter(this.get('data-filter'));
      });

      self._selectFilter(self.itemFilter);

      let defaultText = $('emptyBrowseNotice');
      let mediaItemCount = $('mediaItemCount');

      self.events.addEvent('itemCountChanged', function () {
         let count = self.getItemCount(),
            empty = count === 0;

         // Update item count
         mediaItemCount.set('text', count);

         // Show default text if item count is 0
         defaultText.toggle(empty);
      });
   },

   /**
    * Select the specified filter and update the UI
    *
    * @param string one of all, images, video
    * Note: should be the same as the data-filter attribute on each filter.
    */
   _selectFilter: function (filter) {
      let filterButtons = $$('.itemFilter'),
         library = this;

      library.dismissAlerts();

      filterButtons.each(function (button) {
         let isSelected = button.get('data-filter') === filter;
         button.toggleClass('active', isSelected);
         if (isSelected) {
            library._deleteAll.set('html', button.get('data-deleteAllText'));
            library.itemFilter = button.get('data-filter');
         }
      });

      let filterText = $E('.itemFilter.active').get('text');
      $E('.js-current-display-name').set('text', filterText);

      let sortText = $E('.sortOption.active').get('text');
      $E('.js-current-sort-name').set('text', sortText);

      library.browseWindow.set('class', filter);

      // Update default no items text based on the filter selected
      library.events.fireEvent('itemCountChanged');
   },

   _setupDeleteAll: function () {
      let deleteAll = this._deleteAll;
      let library = this;
      let showingDeleteAll = false;

      this.events.addEvent('itemCountChanged', function () {
         deleteAll.toggle(library.getItemCount() > 0);
      });

      // Hide it by default, it gets shown the first time an item is added.
      deleteAll.hide();
      deleteAll.addEvent('click', function (event) {
         event.stop();

         if (showingDeleteAll) {
            return;
         }
         showingDeleteAll = true;

         let mediaType = $$('.itemFilter.active').pick().get('text').toLowerCase(),
            question = _js('Are you sure you want to delete ALL your ');

         mediaType = mediaType === 'all' ? 'media' : mediaType;

         let msg = Utils.createAlertPrompt(
            'notice',
            question + mediaType + '?',
            function (choseYes) {
               // deleteAllCallback
               showingDeleteAll = false;
               if (!choseYes) {
                  return;
               }
               // Clone the array using filter() because deleting a mediaItem removes
               // it from the array.
               library.mediaItems.clean().each(function (mediaItem) {
                  let filter = library.itemFilter;
                  if (filter === 'all' || filter === mediaItem.getFilter()) {
                     mediaItem.deleted();
                  }
               });
            }
         );

         library._appendAlert(msg);
      });
   },
};

export default MediaLibrary;

Object.append(MediaLibrary, Utils.EventsFunctions);
MediaLibrary.initialize();

/**
 * A wrapper for FileDrop to make it behave as expected by our code.
 */
// eslint-disable-next-line no-var
var FileDropUploader = new Class({
   Implements: [Options, Events],

   options: {
      uploadUrl: '',
      container: null,
   },

   initialize: function (options) {
      let fileDropUploader = this;
      let submitBuffer = [];
      let imageExtensions = Object.keys(MediaLibrary.imageExtensions);
      let documentExtensions = Object.keys(MediaLibrary.documentExtensions);
      let videoExtensions = Object.keys(MediaLibrary.videoExtensions);
      let extensions = imageExtensions.concat(documentExtensions, videoExtensions);
      let imageExtensionText = imageExtensions.join(', ').toUpperCase();
      let documentExtensionText = documentExtensions.join(', ').toUpperCase();
      let videoExtensionText = videoExtensions.join(', ').toUpperCase();

      let limitMessage = _js('File size limit: %1 MB. Video length limit: %2 seconds.')
         .replace('%1', App.maxMediaSize)
         .replace('%2', App.maxMediaLength);

      this.setOptions(options);
      let uploadText = _js('browse');
      let batchId = Date.now();
      let updateBatchId = function () {
         batchId = Date.now();
      };
      let url = function () {
         return (
            fileDropUploader.options.uploadUrl + '?uploadBatchId=' + batchId + '&csrf=' + CSRF.get()
         );
      };
      let template = window.hbsTemplates('MEDIA_LIBRARY_UPLOADER_HBS');

      let data = {
         imageExtensionText: imageExtensionText,
         documentExtensionText: documentExtensionText,
         videoExtensionText: videoExtensionText,
         limitMessage: limitMessage,
      };
      let elem = template(data);
      elem.inject(this.options.container);

      let uploadQueue = (function (queueSize) {
         let index = 0;
         let queue = [];

         function trigger(runnable) {
            index++;
            // Run runnable asynchronously
            window.setTimeout(runnable);
         }

         function triggerNext() {
            let item = queue.pop();
            trigger(item);
         }

         return {
            send: function send(runnable) {
               if (index < queueSize) {
                  // Run trigger asynchronously
                  trigger(runnable);
               } else {
                  queue.push(runnable);
               }
            },
            next: function next() {
               index--;
               if (queue.length > 0) {
                  triggerNext();
               }
            },
         };
      })(/* queue size */ 3);

      window.fd.logging = false;

      let createZone = function (elem, iframe) {
         let config = {
            multiple: true,
         };

         if (iframe) {
            config.iframe = {
               url: url(),
               callbackParam: 'fd-callback',
               fileParam: 'file',
            };
         } else {
            config.input = false;
         }

         let zone = new FileDrop(elem, config);

         let sendFile = function (file, id) {
            file.event('progress', function (sent, total) {
               triggerProgress(id, file.name, sent, total);
            });
            file.event('done', function (xhr) {
               uploadQueue.next();
               let response = JSON.parse(xhr.response);
               let message = _js('Unknown error while uploading file: %1').replace('%1', file.name);
               if (response && response.success) {
                  fileDropUploader.fireEvent('upload', [id, file.name, response]);
               } else {
                  if (response && response.error) {
                     let error = response.error;
                     if (response.filename && error.message) {
                        message = response.filename + ': ' + error.message;
                     } else if (response.filename) {
                        message = _js('Unknown error while uploading file: %1').replace(
                           '%1',
                           response.filename
                        );
                     } else if (error.message) {
                        message = file.name + ': ' + error.message;
                     }
                  }
                  fileDropUploader.fireEvent('error', [id, file.name, message]);
               }
            });
            file.event('error', function (event, response) {
               let message = _js('Unknown error while uploading file: %1').replace('%1', file.name);
               fileDropUploader.fireEvent('error', [id, file.name, message]);
               uploadQueue.next();
            });
            uploadQueue.send(function () {
               file.sendTo(
                  url() +
                     '&file=' +
                     encodeURIComponent(file.name) +
                     '&uploadIndex=' +
                     encodeURIComponent(id)
               );
            });
            fileDropUploader.fireEvent('added', [id, file.name, file.abort.bind(file)]);
         };
         let handleSend = function (files) {
            updateBatchId();
            files.each(sendFile);
         };
         let handleIframeSetup = function (iframe) {
            // Update the upload URL (and the CSRF cookie)
            iframe.action = url();
            // It seems that this event is only triggered if an iframe upload
            // actually takes place.
            fileDropUploader.fireEvent('added', ['a', 'uploading', null]);
            fileDropUploader.fireEvent('progress', ['a', 'uploading', 0]);
         };
         let handleIframeDone = function (res) {
            if (res.error) {
               let error = res.error;
               if (error && res.filename && error.message) {
                  error = res.filename + ': ' + error.message;
               }
               fileDropUploader.fireEvent('error', ['a', res.filename, error]);
            } else {
               fileDropUploader.fireEvent('upload', ['a', 'uploading', res]);
            }
         };
         zone.event('send', handleSend);
         zone.event('iframeSetup', handleIframeSetup);
         zone.event('iframeDone', handleIframeDone);
         return zone;
      };

      createZone(elem, true);
      createZone($('libraryContent'));

      function triggerProgress(id, filename, loaded, total) {
         let percent = (loaded && total && loaded / total) || 0;
         fileDropUploader.fireEvent('progress', [id, filename, percent]);
      }
   },
});

/**
 * Wraps any uploader that exposes an 'upload' event, and creates a new
 * MediaItem instance from the upload data. Exposes a new 'upload' event that
 * includes the constructed MediaItem, and takes care of handling the
 * post-upload processing step for MediaItem uploads.
 */
// eslint-disable-next-line no-var
var MediaItemUploader = new Class({
   _items: {},

   Implements: [Options, Events],

   initialize: function (uploader, options) {
      this.setOptions(options);
      uploader.addEvent(
         'upload',
         function (id, filename, data) {
            // The new upload still needs to go through processing by an
            // asynchronous server process; we create the new MediaItem with a
            // promise and periodically contact the server to check whether
            // processing is complete. When it is we resolve the promise, which
            // will update the MediaItem.
            let dataPromise = new Future();
            let mediaItem = MediaItem.createFromData(data, {}, dataPromise);
            dataPromise.getValue(function (responseData, err) {
               if (err) {
                  uploader.fireEvent('error', [data.imageid, data.filename, err, mediaItem]);
               }
            });

            new Request.AjaxIO('isMediaItemReady', {
               onComplete: function (responseData) {
                  if (responseData.error) {
                     dataPromise.error(responseData.error);
                  } else {
                     dataPromise.resolve(responseData);
                  }
               },
            }).ping(data.guid, data.type, this.options.mediaFilterName);

            // Make the new MediaItem available right away.
            this.fireEvent('upload', [id, mediaItem, filename]);
         }.bind(this)
      );

      Utils.passEvent('added', uploader, this);
      Utils.passEvent('error', uploader, this);
      Utils.passEvent('progress', uploader, this);
      Utils.passEvent('clobber', uploader, this);
   },
});

// eslint-disable-next-line no-var
var UploadingItem = new Class({
   initialize: function (cancelFunction) {
      function create(str) {
         return new Element(str);
      }

      let item = this,
         element = (this.element = create('div.mediaItem.uploading')),
         spacer = (this.spacer = create('div.spacer')),
         filecontainer = (this.fileEl = create('span.file-container')),
         filenameEl = (this.filenameEl = create('span.filename')),
         filetypeEl = (this.filetypeEl = create('span.filetype')),
         percentDisplay = (this.percentEl = create('span.percent')),
         percentBar = (this.percentBar = create('div.percentBar'));
      spacer.grab(percentDisplay);
      spacer.grab(percentBar);
      if (cancelFunction) {
         let cancel = create('div.cancel.close')
            .set('html', '&times;')
            .addEvent('click', function () {
               item.dispose();
               cancelFunction();
            });
         spacer.grab(cancel);
      }
      element.grab(spacer);
      filecontainer.grab(filenameEl);
      filecontainer.grab(filetypeEl);
      element.grab(filecontainer);
      this.setProgress(0);
   },
   setFilename: function (filename) {
      if (filename && !this._filenameSet) {
         this._filenameSet = true;
         this.filenameEl.set({
            text: filename,
            title: filename,
         });
         /**
          * Set the class (for filtering) based on the file-extension
          */
         let extension = filename.match(/\.[^.]+$/);
         if (extension) {
            let extensions = MediaLibrary.allExtensions;
            let type = extensions[extension[0].substring(1).toLowerCase()];
            let classes = {
               i: 'mediaImage',
               v: 'mediaVideo',
               d: 'mediaDocument',
            };
            this.element.addClass(classes[type]);
         }
      }
      return this;
   },
   setProgress: function (percent) {
      let percentText = Math.round(percent * 100) + '%';

      this.percentEl.set('text', percentText);
      this.percentBar.setStyle('width', percentText);
      if (percent >= 1) {
         this.clearProgress();
      }
      return this;
   },
   dispose: function () {
      this.element.dispose();
   },
   clearProgress: function () {
      let item = this;
      item.element.addClass('mediaItemProcessing');
      // Fade out the progress bar
      TweenMax.to(item.spacer, 0.5, { opacity: 0 });
      setTimeout(function () {
         item.percentBar.dispose();
         item.percentEl.dispose();
         item.spacer.dispose();
      }, 1000);
      return item;
   },
});
