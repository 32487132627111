import { Utils } from 'Shared/utils';
import { StatusNotice } from 'Shared/status_notice';
import { LoadingStatus } from 'Shared/modal';
import StrictObject from 'strict-object';

/**
 * See docs in the README for this directory
 *
 * Class MediaItem
 *
 * Represents an image, embeddable, or a video. It has associated data that
 * can be updated, a loading state,
 *
 * Instance Events:
 *    loadingChanged(bool): fired when the "loading" state is entered or
 *       exited and passed the new value of the loading state.
 *       When displaying a mediaItem, the loading state should be visualized
 *
 *    dataChanged(): Fired after the .data attribute has changed.  Typically
 *       this means the media item was cropped or marked.
 *       When displaying a media item, the display will likely need to be
 *       updated after the data changes.
 *
 * Class Events: use MediaItem.addEvent(...)
 *    toggleMenu(mediaItem, control): fired when the menu should be shown /
 *       hidden for a media item.  Control is the element that triggered the
 *       event.
 *
 * When extending this class
 */
export const MediaItem = (window.MediaItem = new Class({
   Implements: [Options, Events],

   options: {
      enableCrop: true,
      enableMarkers: true,
      min: {
         width: 800,
         height: 600,
      },
      cropMin: {
         width: 96,
         height: 96,
      },
      ratio: 'FOUR_THREE',
   },

   medium: {
      width: 592,
      height: 444,
   },

   initialize: function (data, options, dataPromise) {
      this.setOptions(options);
      this.storage = new Hash();

      // If there's a dataPromise present then this MediaItem corresponds to a
      // new upload for which there isn't any thumbnail yet; we save the data
      // we have without triggering any events and then wait for the complete
      // data to load.
      if (dataPromise) {
         this.setData(data, /* suppressEvent = */ true);
         this.setDataPromise(dataPromise);
      } else {
         this.setData(data);
      }
   },

   /**
    * Sets the "loading" state for this mediaItem and fires the loadingChanged
    * event.
    */
   _setLoading: function (isLoading) {
      // Force a boolean conversion
      isLoading = !!isLoading;

      if (this.isLoading == isLoading) {
         return;
      }

      this.isLoading = isLoading;
      this.fireEvent('loadingChanged', [isLoading]);
   },

   /**
    * Changes the data behind this mediaItem and fires the dataChanged event.
    */
   setData: function (data, suppressEvent) {
      if (!(data instanceof MediaItemData)) {
         data = new MediaItemData(data);
      }

      this.data = data;

      if (!suppressEvent) {
         this.fireEvent('dataChanged', [this]);
      }
   },

   /**
    * Sets the data for this mediaItem by accepting a Promise object
    *
    * This is used when the underlying data in a media item has changed,
    * but the new data isn't available yet.  i.e. Waiting on a crop operation
    *
    * See: future.js
    */
   setDataPromise: function (dataPromise) {
      let self = this;
      this.dataPromise = dataPromise;

      // only change the "loading" state if the data wasn't immediately
      // available.
      if (!dataPromise.isResolved()) {
         self._setLoading(true);
      }

      dataPromise.getValue(function (data, err) {
         if (err) {
            alert(err);
         } else {
            self.setData(data);
         }

         delete self.dataPromise;
         self._setLoading(false);
      });
   },

   /**
    * Calls the readyCallback immediately, or when the data promise object
    * (from setDataPromise) is resolved.
    *
    * If the data is not available immediately, the notReadyCallback will
    * be called.
    */
   whenReady: function (readyCallback, notReadyCallback) {
      if (this.dataPromise) {
         if (!this.dataPromise.isResolved() && notReadyCallback) {
            notReadyCallback();
         }
         return this.dataPromise.getValue(readyCallback, notReadyCallback);
      } else {
         if (readyCallback) {
            readyCallback();
         }
         return true;
      }
   },

   /**
    * Fires the "delete" event for this MediaItem and then removes all of its
    * events.
    *
    * Subscribers will listen to this even and perform the appropriate action.
    * i.e. MediaTargets clear their field and their UI, the library fires a
    * request...
    */
   deleted: function () {
      this.fireEvent('deleted', [this]);
      this.removeEvents();
   },

   /**
    * Returns true if the current Data attributes are ready to be read
    * and used, false if they will be available in the future.
    *
    * See: whenReady()
    */
   isReady: function () {
      // This is verbose, but clear, don't condese it.
      let data = this.dataPromise;
      if (data) {
         return data.isResolved();
      } else {
         return true;
      }
   },

   /**
    * Sets the container that this mediaItem is currently in.
    *
    * context: an instance of MediaLibrary or MediaTarget
    */
   setContext: function (context) {
      this.context = context;
   },

   getContext: function () {
      return this.context;
   },

   getElement: function () {
      return this.container;
   },

   /**
    * See: collectMenuOptions
    *
    * Note: Override this in subclasses to add / remove items, individual
    * results will override the base class options.
    *
    * returns something like:
    * {
    *    crop: true, // item is shown
    *    markers: false, // item is hidden
    *    copy: "not allowed to copy here", // item is disabled and this string
    *                                      // is show in a tooltip when it's
    *                                      // hovered
    *    ...
    * }
    */
   _getMenuOptions: function () {
      return {
         deleted: true,
         copy: true,
      };
   },

   /**
    * Returns the name of the largest image size possible but caps it at
    * sizeLimit (thumbnail, standard, etc.)
    */
   getLargestImageSize: function (sizeLimit) {
      let i = sizeLimit ? MediaItem.sizes.indexOf(sizeLimit) : MediaItem.sizes.length - 1;

      for (i; i >= 0; i--) {
         let size = MediaItem.sizes[i];
         let data = this.data;

         if (data[size] && data[size]()) {
            return size;
         }
      }
   },

   /**
    * Returns an object with name => boolean mappings that indicates which
    * menu options should be shown / hidden when the menu is displayed for this
    * mediaItem in this context.
    *
    * Note: don't override this function in subclasses.
    */
   collectMenuOptions: function () {
      // Get the list of menu Items from the base class
      let menuItems = MediaItem.prototype._getMenuOptions.call(this);

      // Add ones that are specific to this class
      Object.append(menuItems, this._getMenuOptions());

      // Allow the context (library, target, ...) to have a say
      if (this.context) {
         Object.append(menuItems, this.context.getMenuItems(this));
      }

      return menuItems;
   },

   store: function (key, value) {
      this.storage.set(key, value);
   },

   retrieve: function (key, fallback) {
      let value = this.storage.get(key);
      if (!value && fallback) {
         value = fallback;
         this.storage.set(key, value);
      }
      return value;
   },

   /**
    * Create a representation of this media item suited for the provided target
    * and pass it into the callback once it's ready to be inserted into the
    * DOM.
    */
   createRepresentationFor: function (mediaTarget, callback) {
      // Override this function in sub-classes to provide specific
      // functionality. The new function should create a representation of the
      // MediaItem and pass it into the callback. This is to support waiting
      // for assets (e.g. images) to load before they're inserted into the DOM.
   },

   /**
    * Returns a unique identifier for this item (among media items of the same
    * type). For example the unique identifier for an image would be its
    * imageid, the unique identifier for an object it objectid, and so on.
    */
   getID: function () {
      // Override this function in sub-classes to provide specific
      // functionality
   },

   getType: function () {
      // Override this function in sub-classes to provide specific
      // functionality
   },

   /**
    * Returns a unique identifier for this item across all media types. Note
    * that this is different from getID, because two items of different media
    * types might have the same ID (e.g. 1), but must have different GlobalIDs
    * because they're different types (e.g. image:1 and object:1).
    */
   getGlobalID: function () {
      // Override this function in sub-classes to provide specific
      // functionality
   },

   /**
    * Return `true` if the server's filter will accept this
    * `MediaItem` in the current `MediaTarget`.
    */
   isValidChoice: function () {
      if (!this.isReady()) {
         return false;
      }
      if (this.data.filter_state() === undefined) {
         return true;
      }
      return (
         this.data.filter_state() === true ||
         this.data.filter_state() === 'crop' ||
         this.data.filter_state() === 'reduced_quality'
      );
   },

   // Copy a mediaItem into the user's library.
   copyToMediaLibrary: function () {
      MediaItem.copyToMediaLibrary(this.getID(), this.getType());
   },
}));

MediaItem.extend({
   // This static method is only here because images can be copied from within
   //  the Item Drawer which doesn't use mediaItems to represent pictures.
   //  Move this functionality inside of the above member function when Item
   //  Drawer has been replaced.
   copyToMediaLibrary: function (id, type, callback, modalContainer) {
      let loadingStatus = new LoadingStatus(modalContainer ? modalContainer : $(document.body));

      loadingStatus.loading(_js('Copying to library...'));

      new Request.AjaxIO('copyToLibrary', {
         onSuccess: function (response) {
            loadingStatus.doneLoading.delay(
               type === 'GuideVideoObject' || type === 'GuideEmbedObject' ? 1000 : 0,
               loadingStatus
            );
            callback && callback(response, id);
         },
         onFailure: function () {
            loadingStatus.doneLoading();
            loadingStatus.loading(_js('Internal Error: Failed copying to library.'));
            loadingStatus.doneLoading.delay(3000, loadingStatus);
         },
         onError: function (response) {
            loadingStatus.doneLoading();
            let notice = new StatusNotice();
            $$('#guideSteps .statuses').adopt(notice.element);
            notice.error(response.error);
            notice.addCloseButton();
         },
      }).send(id, type);
   },

   /**
    * This returns null when menuHide is set to 'all', thus preventing the
    * Edit menu from being rendered.
    */
   createMenuControl: function (mediaItem) {
      if (
         mediaItem &&
         mediaItem.context &&
         mediaItem.context.options &&
         mediaItem.context.options.menuHide === 'all'
      ) {
         return null;
      }
      let control = new Element('div.menuControl'),
         icon = new Element('i.fa.fa-cog'),
         editText = new Element('div.edit-text');

      editText.setProperties({
         text: _js('edit').toUpperCase(),
      });

      editText.grab(icon);
      control.grab(editText);
      control.addEvent('click', function (ev) {
         ev.stop();
         MediaItem.fireEvent('toggleMenu', [mediaItem, control]);
      });
      control.store('mediaItem', mediaItem);
      return control;
   },

   /**
    * Create a new instance of a particular media type based on data sent from
    * the server, and options from the media library.
    */
   createFromData: function (data, options, dataPromise) {
      data = new MediaItemData(data);
      let classToCreate = this.types[data.type()];
      return new classToCreate(data, options, dataPromise);
   },

   types: {},
   registerType: function (type, classToRegister) {
      this.types[type] = classToRegister;
   },

   /**
    * Ordered array of image sizes (smallest to biggest)
    */
   sizes: ['mini', 'thumbnail', 'standard', 'medium', 'large', 'huge', 'original'],

   isSizeAsBigAs: function (compareSize, fixedSize) {
      let fixedPos = this.sizes.indexOf(fixedSize),
         comparePos = this.sizes.indexOf(compareSize);
      if (fixedPos === -1 || comparePos === -1) {
         let sizes = [fixedSize, compareSize].join(',');
         throw new Error('One of [' + sizes + '] is not a valid image size.');
      }
      return comparePos >= fixedPos;
   },
});

/**
 * Add events capability to the MediaItem object itself.
 *
 * This allows consuming and firing events directly on the MediaItem Class
 *     MediaItem.addEvent(...)
 *     MediaItem.fireEvent(...)
 */
Object.append(MediaItem, Utils.EventsFunctions);

/**
 * The data object that comes from the server and is used to
 * create a MediaItem.
 *
 * Note: not all properties always available
 */
export const MediaItemData = (window.MediaItemData = StrictObject.define([
   // original filename from the uploaded image
   'filename',
   // optional title, often pulled from metadata
   'title',
   // actualy media type: guide_image, embed, video, ...
   'type',
   // the type of embed it is: video, link, rich, etc.
   'provider',
   // uploaded_on or created_on
   'date',
   // imageid of the source image (if this is cropped or marked up)
   'srcid',
   'imageid',
   'encoding',
   // For MediaItemDocuments
   'documentid',
   'objectid',
   'videoid',
   'document_extension',
   'document_type',
   'document_group',
   // unique identifier that's in urls
   'guid',
   // String of the ratio type: "FOUR_THREE", ...
   'ratio',
   // ?
   'active',
   // URL's of available sizes (not null == exists)
   'mini',
   'thumbnail',
   'standard',
   'medium',
   'original',
   // Url that is a view of this object
   'view_url',
   // Url for editing the document
   'edit_url',
   // Object-map of video urls {ogg: "http..", webm: "http..", ...}
   'video_urls',
   // Pixel dimensions of original size
   'width',
   'height',
   // JS object of markup info
   'markup',
   // Serialized markup info
   'markup_string',
   // Dimensions for marker stuff
   'scaled_width',
   'scaled_height',
   // Filter passing state
   'filter_state',
   // Rendered HTML for embeds
   'html',
]));
