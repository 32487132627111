import { Utils } from 'Shared/utils';
import { Modal } from 'Shared/modal';
import { CookieUtils } from 'Shared/cookies/cookie-utils';

// eslint-disable-next-line no-var
export var Auth = (window.Auth = new Class({}));

Object.append(Auth, {
   protocol: window.location.protocol.slice(0, -1),
   cookies: [],

   defaultOptions: {
      from: 'navLogin',
      onAuthorize: function () {},
      close: true,
      reload: false,
      locked: false, // locked and noOverlay are used at /Login
      noOverlay: false,
      stayInWindow: false,
   },

   login: function (options) {
      options = Object.merge({}, this.defaultOptions, options);

      if (this.trySSOLogin(options)) {
         return;
      }

      Modal.open({
         type: 'module',
         name: 'Login',
         boxClass: 'loginModal',
         locked: options.locked,
         noOverlay: options.noOverlay,
         onCancel: options.onCancel,
         serverOptions: {
            register: options.register,
         },
         clientOptions: {
            onAuthorize: options.onAuthorize,
            close: options.close,
            reload: options.reload,
         },
      });
   },

   logout: function () {
      window.location = window.shared_constants.GuideURI('TAG_LOGOUT');
   },

   trySSOLogin: function (options) {
      let ssoURL = App['single-sign-on-url'],
         ignoreSSO = options && options.ignoreSSO,
         cookieOptions = {
            maxAgeInSeconds: 840,
         };

      // Bail out early if we shouldn't use SSO
      if (App['multi-login'] || !ssoURL || ignoreSSO) {
         return false;
      }

      if (options.register) {
         ssoURL = ssoURL.register;
      } else {
         ssoURL = ssoURL.login;
      }

      // If we are supposed to reload the page after logging in use the
      // current window for the SSO redirecting
      if (options.reload || options.stayInWindow) {
         CookieUtils.set('sso-origin', window.location.href, cookieOptions);
         window.location = ssoURL;

         // Otherwise, do the SSO login in a new window
      } else {
         // Set the origin to a special url that temote_login checks for and
         // instead of redirecting, will just close the window.
         CookieUtils.set(
            'sso-origin',
            window.shared_constants.GuideURI('TAG_SSO_CLOSE_WINDOW'),
            cookieOptions
         );

         let loginWindow = window.open(ssoURL, 'sso_window');
         let self = this;
         Utils.onChildWindowClose(loginWindow, function (closed) {
            // if the window wasn't closed, then it timed out and we should
            // ignore it.
            if (!closed) {
               return;
            }

            let jsonRequest = new Request.JSON({
               url: window.shared_constants.GuideURI('TAG_USER_DETAILS'),
               onSuccess: self.responseHandler,
            }).get();
         });
      }

      return true;
   },

   clearCookies: function () {
      this.cookies.forEach(cookieName => Cookie.dispose(cookieName));
      this.cookies = [];
   },

   sendFacebookLogin: function (handler) {
      this.onComplete = handler;

      // meta and www share the same session cookie.
      let domain = window.location.toString().replace(/^[^\/]+\/\/([^\/]+).*$/, '$1');
      domain = domain.replace(/^meta\./, 'www.');

      new Element('script', {
         src:
            this.protocol +
            '://' +
            domain +
            window.shared_constants.GuideURI('TAG_LOGIN') +
            '/facebookLogin?' +
            Number.random(0, 999999999),
      }).inject(document.body);

      // If the request fails, remove the cookies.
      this.clearCookies.delay(15000, this);
   },

   sendLogin: function (options) {
      this.onComplete = options.handler;

      let login = options.login,
         password = options.password,
         googleLoginData = options.googleLoginData,
         googleToken = null;

      if (googleLoginData) {
         googleToken = googleLoginData.credential;
      }

      let self = this;
      let data = {
         login: login,
         password: password,
         googleToken: googleToken,
         captchaResult: options.captchaResult,
      };

      return new Request.AjaxIO('login').send(data).then(response => {
         response.login = login;
         self.responseHandler(response);
         return response;
      });
   },

   sendRegister: function (formValues, handler) {
      this.onComplete = handler;

      // meta and www share the same session cookie.
      let domain = window.location
         .toString()
         .replace(/^[^\/]+\/\/([^\/]+).*$/, '$1')
         .replace(/^meta\./, 'www.');

      let options = {
         maxAgeInSeconds: 18,
         path: '/',
         domain: domain.replace(/^(www|meta)\./, '.'),
      };

      this.cookies.append([
         CookieUtils.set('login', formValues.login, options),
         CookieUtils.set('username', formValues.username, options),
         CookieUtils.set('uniqueUsername', formValues.uniqueUsername, options),
         CookieUtils.set('password1', formValues.password1, options),
         CookieUtils.set('termsAgree', formValues.termsAgree, options),
         CookieUtils.set('inviteCode', formValues.inviteCode, options),
         CookieUtils.set('captchaResult', formValues.captchaResult, options),
      ]);

      let path =
         App.redirectPath ||
         window.shared_constants.GuideURI('TAG_LOGIN') +
            '/sslRegister?' +
            Number.random(0, 999999999);

      new Element('script', {
         src: this.protocol + '://' + domain + path,
      }).inject(document.body);

      // If the request fails, remove the cookies.
      this.clearCookies.delay(15000, this);
   },

   required: function (options) {
      if (!this.isLoggedIn()) {
         if (options.reload) {
            options.onAuthorize = function () {};
         }
         this.login(options);
         return false;
      } else if (options.onAuthorize) {
         options.onAuthorize();
      }
      return true;
   },

   responseHandler: function (details) {
      if (details.loggedIn) {
         Auth.loggedIn(details);
      } else {
         Auth.loggedOut(details);
      }
      if (this.onComplete) {
         this.onComplete(details);
      }
   },

   loggedIn: function (info) {
      Header.showLoggedIn(info);
      App.isLoggedIn = true;

      /**
       * Send user data over to Aptrinsic for session tracking.
       * https://docs.aptrinsic.com/install-aptrinsic/lets-get-installed-the-configuration-checklist
       */
      if (info.userid && info.username && info.aptrinsic && window.aptrinsic) {
         window.aptrinsic(
            'identify',
            {
               id: `${info.aptrinsic.siteid}-${info.userid}`,
               email: info.login,
               firstName: info.username.replace(/\s+.*$/, ''),
               lastName: info.username.indexOf(' ') > 0 ? info.username.replace(/.*\s+/, '') : '',
               privilegeid: info.aptrinsic.privilegeid,
               privilege: info.aptrinsic.privilege,
               planid: info.aptrinsic.planid,
               plan_name: info.aptrinsic.plan_name,
            },
            {
               id: info.aptrinsic.siteid,
               website: window.location.hostname,
               name: info.aptrinsic.sitename,
            }
         );
      }
   },

   loggedOut: function (info) {
      Header.showLoggedOut();
      App.isLoggedIn = false;
      /**
       * Simple hack to force the user to go to the home page when logging
       * out on specific pages. The real fix would be to pass in a funcion
       * for log out, but Shawn should handle that and he's too busy.
       *
       * To add a new page add a unique identifer from the URL to the array.
       */
      let location = window.location.href;
      ['edit-profile', 'view-profile'].each(function (item, index) {
         if (location.indexOf(item) > -1) {
            window.location = window.shared_constants.GuideURI('TAG_SITE_HOMEPAGE');
         }
      });
      if (info && info.reload) {
         window.location.reload();
      }
   },

   isLoggedIn: function () {
      return App.isLoggedIn;
   },
});

Object.append(Auth, Utils.EventsFunctions);
