import { Utils } from 'Shared/utils';

/**
 * Allows simple and standard success / notice / error messages to be displayed
 * from javascript.
 *
 * Usage:
 * var notice = new StatusNotice();
 * // notice.element can be inserted anywhere into your document.
 * someElement.adopt(notice.element);
 *
 * notice.notice("Yo, something happened");
 * notice.success("Great job!")
 *
 * To add a close button
 * someElement.adopt(notice.element);
 * notice.notice("Yo, something happened");
 * notice.addCloseButton();
 * //...
 */
// eslint-disable-next-line no-var
export var StatusNotice = (window.StatusNotice = new Class({
   initialize: function (element) {
      if (element) {
         // If the notice was already rendered on the backend, then the user
         // can just pass in its element.
         this.element = element;
      } else {
         // Otherwise, create a new element.
         let createElement = Utils.createElements;

         this.element = createElement({
            children: [
               createElement({
                  tag: 'p',
               }),
            ],
            tag: 'div',
            c: 'status inPage',
         });
         this.hide();
      }

      this.status = this.element.getElement('p');
   },

   info: function (msg) {
      this.update(msg, 'statusInfo');
   },

   notice: function (msg) {
      this.update(msg, 'statusNotice');
   },

   good: function (msg) {
      this.update(msg, 'statusSuccess');
   },

   error: function (msg) {
      this.update(msg, 'statusError');
   },

   update: function (msg, className) {
      this.status.set('text', msg);
      this.element.set('class', className + ' inPage status');
      this.element.show();
   },

   hide: function () {
      this.element.hide();
   },

   /**
    * Must be called after the notice is displayed.
    */
   addCloseButton: function () {
      new Element('i.fa.fa-times.right.js-status-cancel')
         .addEvent('click', this.close.bind(this))
         .inject(this.status);
   },

   close: function () {
      this.element.dispose();
   },

   scroll: function () {
      new Fx.Scroll(window).toElement(this.element);
   },

   // Todo add timeout
}));
