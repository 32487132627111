import { tabletDetected, mobileDetected } from 'Shared/utils';
import { Tooltip } from 'Shared/tooltips';

/**
 * Class MediaItemMenu
 */
export default new Class({
   Implements: [Options, Events],

   options: {
      tooltip: {
         autohide: true,
         hideDelay: 700,
         side: 'top',
         arrowAlign: 'right',
         arrowInset: 10,
         nudge: -19,
         zIndex: 1000100,
      },
   },

   initialize: function () {
      this.sides = {};
      this.items = {};
      this.isOpen = false;

      // Fires events for each of the menu items
      this.menuItems = new Events();

      this.menu = new Element('div.mediaItemMenu', {
         events: {
            mousedown: function (ev) {
               ev.stop();
            },
         },
      }).adopt(
         (this.sides.left = new Element('ul.left')),
         (this.sides.right = new Element('ul.right')),
         (this.sides.bottom = new Element('div.bottom.clearer'))
      );

      this.tooltip = new Tooltip(null, this.options.tooltip);
      /*
       * TODO: Move this to ImageMediaItem
      this.tooltip.addEvent('hide', function() {
         this.control.removeClass('active');
         this.control.removeEvents('clickoff');
         this.isOpen = false;
      }.bind(this));
      */

      this.addMenuItems();
   },

   addMenuItems: function () {
      let items = {
         // Full-size menu item.
         fullsize: {
            side: 'left',
            text: _js('Full Size'),
            icon: 'iconFullsize',
         },

         /**
          * Delete menu item.
          * The even name is 'deleted' so it doesn't clash with the keyword
          */
         deleted: {
            side: 'right',
            text: _js('Delete'),
            icon: 'iconDelete',
         },

         // Copy item to media manager
         copy: {
            side: 'right',
            text: _js('Copy'),
            icon: 'iconCopy',
            hoverText: _js('Copy to Media Manager'),
         },
      };

      // Only Allow markers and cropping on non-mobile browsers
      if (!mobileDetected() && !tabletDetected()) {
         // Markers menu item.
         items.markers = {
            side: 'left',
            text: _js('Markers...'),
            icon: 'iconMarkers',
         };

         // Crop menu item.
         items.crop = {
            side: 'left',
            text: _js('Crop...'),
            icon: 'iconCrop',
         };
      }

      Object.each(
         items,
         function (options, menuItemName) {
            this.addItem(menuItemName, options);
         },
         this
      );
   },

   addItem: function (name, options) {
      let self = this;
      let link;
      let item = new Element('li', {
         events: {
            mouseenter: this.hoverItem.bind(this, name),
            mouseleave: this.blurItem.bind(this, name),
            click: function (ev) {
               ev.stop();
               if (this.hasClass('inactive')) {
                  return;
               }
               // Fire the event
               self.menuItems.fireEvent(name, [self.mediaItem]);
               if (options.onSelect) {
                  options.onSelect();
               }
               self._close();
            },
         },
      }).adopt(
         new Element('div.icon.' + options.icon),
         (link = new Element('a', {
            text: options.text,
            events: {
               click: function (ev) {
                  ev.preventDefault();
               },
            },
         }))
      );

      if (options.hoverText) {
         item.tooltip = new Tooltip(item, {
            autoshow: true,
            text: options.hoverText,
            hideDelay: 0,
            zIndex: this.options.tooltip.zIndex + 1,
         });

         item.addEvent('click', item.tooltip.hide.bind(item.tooltip));
      }

      item.inject(this.sides[options.side]);

      // Used to alter the 'title' attr when an item is disabled
      item.store('link', link);
      this.items[name] = item;
      return this;
   },

   disableItem: function (name) {
      this.items[name].addClass('inactive');
      return this;
   },

   enableItem: function (name) {
      this.items[name].removeClass('inactive');
      return this;
   },

   toggleForMediaItem: function (mediaItem, menuAnchor) {
      if (this.tooltip.visible() && mediaItem == this.mediaItem) {
         this._close();
      } else {
         if (this.tooltip.visible()) {
            this.tooltip.hide();
         }
         this.mediaItem = mediaItem;
         let validOptions = mediaItem.collectMenuOptions();
         Object.each(this.items, function (item, name) {
            let validOption = validOptions[name];
            // if the menu item is set to a string, it will be disabled
            // and the tooltip (title attr) will be set to the string
            let hasDisabledMessage = typeOf(validOption) == 'string';
            let link = item.retrieve('link');

            item.toggle(validOption);
            item.toggleClass('inactive', hasDisabledMessage);
            link.set('title', hasDisabledMessage ? validOption : '');
         });
         this._openAtAnchor(menuAnchor);
      }
   },

   /* Private Methods */

   _close: function () {
      this.tooltip.hide();
      return this;
   },

   _openAtAnchor: function (anchor) {
      if (anchor) {
         this.tooltip.setAnchor(anchor);
      }
      this.tooltip.set(this.menu).show();
      /*
       * TODO: Move this to ImageMediaItem
      this.control.addEvent('clickoff', function(ev) {
         var target = ev.target;
         if (target.hasClass('tooltip') || target.getParent('.tooltip')) {
            // Do nothing; clicking the tooltip doesn't close it.
         } else {
            this.close();
         }
      }.bind(this));
      */
      return this;
   },

   _hideItem: function (name) {
      this.items[name].hide();
      return this;
   },

   _showItem: function (name) {
      this.items[name].show();
      return this;
   },

   hoverItem: function (name) {
      let li = this.items[name];
      if (!li.hasClass('inactive')) {
         li.addClass('active');
      }
   },

   blurItem: function (name) {
      let li = this.items[name];
      li.removeClass('active');
   },

   selectItem: function (name, action) {
      let li = this.items[name];
      if (!li.hasClass('inactive')) {
         let closeMenu = action();
         if (closeMenu !== false) {
            this.close();
         }
      }
   },
});
